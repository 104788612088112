import React from 'react';
import { Link } from 'react-router-dom'; // Import Link component
import '../assets/css/BannerFile.css'; // Import CSS file

const Banner: React.FC = () => {
  return (
    <section className="banner-container">
      <div className="banner-content">
        <h1 className="banner-title">Welcome to Biashara Web</h1>
        <p className="banner-subtitle">
          Your go-to platform for E-commerce products & services.
        </p>
        {/* <hr></hr> */}
        <Link to="/products" className="banner-button">Explore products</Link>
        {/* <br />
        <hr /> */}
        <Link to="/services" className="banner-button">Explore services</Link>
        {/* <br />
        <hr /> */}
        <Link to="/adverts" className="banner-button">Explore adverts</Link>
        {/* <hr></hr> */}
      </div>
    </section>
  );
};

export default Banner;
