import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import '../assets/css/FooterFile.css'; // Import CSS file

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        
        {/* Company Info */}
        <div className="footer-section about">
          <h2 className="footer-title">Biashara Web</h2>
          <p className="footer-description">
            Biashara Web is a leading marketing platform offering a wide range of products and services. visit our website and browse for what you love.
          </p>
          <div className="footer-socials">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="footer-social-link">
              <FaFacebookF />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="footer-social-link">
              <FaTwitter />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="footer-social-link">
              <FaInstagram />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="footer-social-link">
              <FaLinkedinIn />
            </a>
            <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer" className="footer-social-link">
              <FaYoutube />
            </a>
          </div>
        </div>

        {/* Quick Links */}
        {/* <div className="footer-section links">
          <h2 className="footer-title">Quick Links</h2>
          <ul className="footer-links">
            <li><a href="/about">About Us</a></li>
            <li><a href="/services">Our Services</a></li>
            <li><a href="/products">Our Products</a></li>
            <li><a href="/blog">Blog</a></li>
            <li><a href="/contact">Contact Us</a></li>
            <li><a href="/faq">FAQs</a></li>
          </ul>
        </div> */}

        {/* Resources */}
        {/* <div className="footer-section links">
          <h2 className="footer-title">Resources</h2>
          <ul className="footer-links">
            <li><a href="/affiliate">Affiliate Program</a></li>
            <li><a href="/partners">Our Partners</a></li>
            <li><a href="/privacy">Privacy Policy</a></li>
            <li><a href="/terms">Terms & Conditions</a></li>
            <li><a href="/support">Support Center</a></li>
            <li><a href="/careers">Careers</a></li>
          </ul>
        </div> */}

        {/* Newsletter Subscription */}
        <div className="footer-section newsletter">
          <h2 className="footer-title">Subscribe to Our Newsletter</h2>
          <p className="footer-description">
            Get the latest updates and offers right in your inbox.
          </p>
          <form className="footer-form">
            <input type="email" placeholder="Enter your email" required className="footer-input" />
            <button type="submit" className="footer-button">Subscribe</button>
          </form>
        </div>

      </div>

      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Biashara Web. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
