// AddBrandDashboard.tsx
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Sidebar from './Sidebar';
import TopNavigation from './TopNavigation';
import AddBrand from './Products/AddBrand';

const AddBrandDashboard: React.FC = () => {

  return (
    <div className="admin-dashboard">
      <TopNavigation />
      <Row className='wrapper'>
        <Col md={2} className="sidebar">
          <Sidebar />
        </Col>
        <Col md={10} className="main-content">
          {/* Pass accessToken as a prop to AddRole */}
          <AddBrand/>
        </Col>
      </Row>
    </div>
  );
};

export default AddBrandDashboard;
