import '../assets/dashboard.css';
import FooterFile from '../components/FooterFile';

// Define the AboutContent component
const AboutContent: React.FC = () => {

  return (
    <div className="dashboard-content">
      
      <section className="bio-section">

          <div className="testimonial-form-container">
          <h3 className='testimonial-title public-title'>Contact Us</h3>
          <form action="path-to-your-backend-endpoint" method="post" encType="multipart/form-data">
            <input type="file" name="profileImage" accept="image/*" />
            <input type="text" name="clientName" placeholder="Name" />
            <input type="email" name="clientEmail" placeholder="Email" />
            <input type="text" name="clientName" placeholder="Subject" />
            <div className="rating-input">
              <input type="radio" name="rating" value="1" id="star1" />
              <label htmlFor="star1">★</label>
              <input type="radio" name="rating" value="2" id="star2" />
              <label htmlFor="star2">★★</label>
            </div>
            <textarea name="testimonialDescription" rows={4} placeholder="Your Message" />
            <button type="submit">Submit</button>
          </form>
        </div>
        </section>
      <hr></hr>
      <hr></hr>
<FooterFile />
    </div>
  );
};

export default AboutContent;
