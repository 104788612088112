import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaTimes, FaMinus, FaPlus, FaTrashAlt } from 'react-icons/fa';
import axios from 'axios'; // Import axios
import { API_BASE_URL } from '../my-api/apiConfig'; // Import API_BASE_URL

interface ProductCartItem {
  id: number;
  product_name: string;
  quantity: number;
  price: number;
  image: string;
}

// Assuming you have other imports for images and components

const SpaBarberPage = () => {

    const navigate = useNavigate();
    const accessToken = localStorage.getItem('accessToken');
    const [productCartItems, setProductCartItems] = useState<ProductCartItem[]>([]); // Define type for productCartItems
    const [isCartOpen, setIsCartOpen] = useState(true);
  
    useEffect(() => {
      const fetchData = async () => {
        if (accessToken) {
          try {
            const response = await axios.get(`${API_BASE_URL}/product_carts`, {
              headers: {
                'Authorization': `Bearer ${accessToken}`,
              }
            });

            const productCartItems = response.data.product_carts.map((item: any) => ({
                id: item.id,
                product_name: item.product_item.product_name,
                quantity: item.product_cart_quantity,
                price: parseFloat(item.product_item.product_price), // Parse price to float if it's a string
                image: `${API_BASE_URL}${item.product_item.images[0]?.path}` // Include a forward slash after API_BASE_URL
              }));
        
              setProductCartItems(productCartItems);
              console.log('Server Product Cart Items:', productCartItems);
            } catch (error) {
              console.error('Failed to fetch productCartItems:', error);
            }
        } else {
          const localStorageCartItems = localStorage.getItem('cartItems');
          console.log('local data:', localStorageCartItems);
          if (localStorageCartItems) {
            const parsedItems = JSON.parse(localStorageCartItems);
            const mappedItems = parsedItems.map((item: any) => ({ // Define type for item
              id: item.product_id,
              product_name: item.product_name,
              quantity: item.product_quantity,
              price: item.product_price,
              image: `${API_BASE_URL}${item.image}` // Include a forward slash after API_BASE_URL
            }));
            setProductCartItems(mappedItems);
            console.log('Local Product Cart Items:', mappedItems);
          }
        }
      };
  
      fetchData();
    }, [accessToken]);

  // Function to close the cart and navigate to homepage
  const handleCloseCart = () => {
    setIsCartOpen(false);
    navigate('/'); // Navigate to homepage
  };

  // Function to clear the cart
  const clearCart = () => {
    localStorage.removeItem('cartItems');
    setProductCartItems([]);
  };

  const handleIncrement = (itemId: number) => {
    setProductCartItems(prevItems =>
      prevItems.map(item =>
        item.id === itemId ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };
  
  const handleDecrement = (itemId: number) => {
    setProductCartItems(prevItems =>
      prevItems.map(item =>
        item.id === itemId && item.quantity > 1 ? { ...item, quantity: item.quantity - 1 } : item
      )
    );
  };
  
  const handleRemove = (itemId: number) => {
    setProductCartItems(prevItems => prevItems.filter(item => item.id !== itemId));
  };
  

  // Calculate total price with VAT and delivery fee
  const calculateTotalPrice = () => {
    return productCartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const calculateGrandTotal = () => {
    const subtotal = calculateTotalPrice();
    const VAT = 0.15; // Assuming VAT is 15%
    const deliveryFee = 10; // Sample delivery fee
    const VATAmount = subtotal * VAT;
    const grandTotal = subtotal + VATAmount + deliveryFee;
    return grandTotal;
  };

  // Navigate to checkout path
  const navigateToCheckout = () => {
    navigate('/checkout-path');
  };

  return (
    <div className="spa-barber-page-container">
      <section className="cart-section">
        <h2>Shopping Cart</h2>
        <div className="cart-items">
          {/* Close button for the cart */}
          {isCartOpen && (
            <Button className="close-cart-button" onClick={handleCloseCart}>
              <FaTimes />
            </Button>
          )}
          {productCartItems.map((item, index) => (
            <div key={item.id} className="cart-item">
              <span className="item-number">{index + 1}</span>
              <img src={item.image} alt={item.product_name} className="cart-item-image" />
              <div className="cart-item-details">
                <div className="cart-item-details-desc">
                  <h3>{item.product_name}</h3>
                  <p>Price per item: KES {item.price}</p>
                  <p>Total price: KES {item.price * item.quantity}</p>
                </div>
                <div className="quantity-control">
                  <Button onClick={() => handleDecrement(item.id)}><FaMinus /></Button>
                  <span>{item.quantity}</span>
                  <Button onClick={() => handleIncrement(item.id)}><FaPlus /></Button>
                  <Button className="checkout-delete" onClick={() => handleRemove(item.id)}><FaTrashAlt /></Button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="total-price">
          <h3>Subtotal: KES {calculateTotalPrice()}</h3>
          <h3>VAT (15%): KES {(calculateTotalPrice() * 0.15).toFixed(2)}</h3>
          <h3>Delivery Fee: KES 10</h3>
          <h3>Grand Total: KES {calculateGrandTotal()}</h3>
        </div>
        <div className="cart-page-navigation">
          <Button className="checkout-button" variant="primary" onClick={navigateToCheckout}>Continue Shopping</Button>
          <Button className="checkout-button" variant="primary" onClick={navigateToCheckout}>Proceed to Checkout</Button>
          <Button className="checkout-button" variant="danger" onClick={clearCart}>Clear Cart</Button>
        </div>
      </section>
    </div>
  );
};

export default SpaBarberPage;
