// src/components/UserManagement/AddUserAccounts.tsx
import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_BASE_URL, API_ENDPOINTS } from '../../my-api/apiConfig';
import Select from 'react-select';

const AddUserAccounts = () => {
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    product_name: '',
    product_description: '',
    product_price: '',
    product_quantity: 0,
    product_category_id: '',
    product_sub_category_id: '',
    product_type_id: '',
    product_brand_id: '',
    age_group_id: '',
    gender_type: '',
    files: [] as File[],
  });

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [types, setTypes] = useState([]);
  const [ageGroups, setAgeGroups] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const [categoryRes, subCategoryRes, brandRes, typeRes, ageGroupRes] = await Promise.all([
          fetch(`${API_BASE_URL}${API_ENDPOINTS.AUTH_PRODUCT_CATEGORIES}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
          }),
          fetch(`${API_BASE_URL}${API_ENDPOINTS.AUTH_PRODUCT_SUB_CATEGORIES}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
          }),
          fetch(`${API_BASE_URL}${API_ENDPOINTS.AUTH_PRODUCT_BRANDS}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
          }),
          fetch(`${API_BASE_URL}${API_ENDPOINTS.AUTH_PRODUCT_TYPES}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
          }),
          fetch(`${API_BASE_URL}${API_ENDPOINTS.AUTH_AGE_SET}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
          }),
        ]);

        if (categoryRes.ok) {
          const categoryData = await categoryRes.json();
          setCategories(categoryData.product_categories);
        }
        if (subCategoryRes.ok) {
          const subCategoryData = await subCategoryRes.json();
          setSubCategories(subCategoryData.product_sub_categories);
        }
        if (brandRes.ok) {
          const brandData = await brandRes.json();
          setBrands(brandData.product_brands);
        }
        if (typeRes.ok) {
          const typeData = await typeRes.json();
          setTypes(typeData.product_types);
        }
        if (ageGroupRes.ok) {
          const ageGroupData = await ageGroupRes.json();
          setAgeGroups(ageGroupData.age_groups);
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };

    fetchOptions();
  }, [accessToken]);


  useEffect(() => {
    if (formData.product_category_id) {
      const filtered = subCategories.filter(
        (subCategory: any) => subCategory.product_category_id === String(formData.product_category_id)
      );
      setFilteredSubCategories(filtered);
    } else {
      setFilteredSubCategories([]);
    }
  }, [formData.product_category_id, subCategories]);
  
  // Handle the category select change
  const handleSelectChange = (selectedOption: any, actionMeta: any) => {
    const { name } = actionMeta;
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption ? selectedOption.value : '', // Clear sub-category when category changes
    }));
    if (name === 'product_category_id') {
      setFormData((prevData) => ({
        ...prevData,
        product_sub_category_id: '', // Reset sub-category if category changes
      }));
    }
  };
  

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: id === 'product_quantity' ? Number(value) : value,
    }));
  };


  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      setFormData((prevData) => ({
        ...prevData,
        files: Array.from(files),
      }));
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('product_name', formData.product_name);
    formDataToSend.append('product_description', formData.product_description);
    formDataToSend.append('product_price', formData.product_price);
    formDataToSend.append('product_quantity', formData.product_quantity.toString());
    formDataToSend.append('product_category_id', formData.product_category_id);
    formDataToSend.append('product_sub_category_id', formData.product_sub_category_id);
    formDataToSend.append('product_type_id', formData.product_type_id);
    formDataToSend.append('product_brand_id', formData.product_brand_id);
    formDataToSend.append('age_group_id', formData.age_group_id);
    formDataToSend.append('gender_type', formData.gender_type);
    formData.files.forEach((file, index) => {
      formDataToSend.append(`files[${index}]`, file);
    });

    try {
      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.AUTH_PRODUCT_LIST_ITEMS}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formDataToSend,
      });

      if (response.ok) {
        const userData = await response.json();
        console.log('Product Item added successfully', userData);

        // Redirect to the view-all product categories page
        navigate('/products/product-listing');
      } else {
        console.error('Error adding product category');
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  return (
    <div className="add-role-container">
      <h2>Add New Product Item</h2>
      <Link to="/products/product-listing" className="role-back-button">
        <i className="fas fa-arrow-left"></i> Back
      </Link>
      <form className="add-role-form" onSubmit={handleSubmit} encType="multipart/form-data">
        <label htmlFor="product_name">Product Item Name:</label>
        <input
          type="text"
          id="product_name"
          placeholder="Product Name"
          value={formData.product_name}
          onChange={handleInputChange}
        />

        <label htmlFor="product_description">Product Item Description:</label>
        <textarea
          id="product_description"
          placeholder="Description"
          value={formData.product_description}
          onChange={handleInputChange}
        />

        <label htmlFor="product_price">Product Item Price:</label>
        <input
          type="text"
          id="product_price"
          placeholder="Product Price"
          value={formData.product_price}
          onChange={handleInputChange}
        />

        <label htmlFor="product_quantity">Product Item Quantity:</label>
        <input
          type="number"
          id="product_quantity"
          placeholder="Product Quantity"
          value={formData.product_quantity}
          onChange={handleInputChange}
        />

        <label htmlFor="product_category_id">Product Category:</label>
        <Select
          name="product_category_id"
          options={categories.map((category: any) => ({ value: category.id, label: category.product_category_name }))}
          onChange={handleSelectChange}
        />

        <label htmlFor="product_sub_category_id">Product Sub-Category:</label>
        <Select
          name="product_sub_category_id"
          options={filteredSubCategories.map((subCategory: any) => ({ value: subCategory.id, label: subCategory.product_sub_category_name }))}
          onChange={handleSelectChange}
        />

        <label htmlFor="product_type_id">Product Type:</label>
        <Select
          name="product_type_id"
          options={types.map((type: any) => ({ value: type.id, label: type.product_type_name }))}
          onChange={handleSelectChange}
        />

        <label htmlFor="product_brand_id">Product Brand:</label>
        <Select
          name="product_brand_id"
          options={brands.map((brand: any) => ({ value: brand.id, label: brand.product_brand_name }))}
          onChange={handleSelectChange}
        />

        <label htmlFor="age_group_id">Age Group:</label>
        <Select
          name="age_group_id"
          options={ageGroups.map((ageGroup: any) => ({ value: ageGroup.id, label: ageGroup.age_group_title }))}
          onChange={handleSelectChange}
        />

        <label htmlFor="gender_type">Gender Type:</label>
        <Select
          name="gender_type"
          options={[
            { value: 'male', label: 'Male' },
            { value: 'female', label: 'Female' },
            { value: 'unisex', label: 'Unisex' },
          ]}
          onChange={handleSelectChange}
        />

        <label htmlFor="files">Product Images:</label>
        <input
          type="file"
          id="files"
          accept="image/*"
          multiple
          onChange={handleImageChange}
        />

        <button type="submit">Add Product Item</button>
      </form>
    </div>
  );
};

export default AddUserAccounts;
