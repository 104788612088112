// src/components/Services.tsx
import React, { useState } from 'react';
import { Container, Card, Button, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
// import '../../assets/services.css';
// import '../../assets/css/UserManagement.css';
interface Collection {
  title: string;
  description: string;
  color: string;
  categories: string[];
}

const Services: React.FC = () => {
  const navigate = useNavigate();
  const collections: Collection[] = [
    {
      title: 'Roles & Permissions',
      description: 'Create Roles & Assign Permissions to users',
      color: '#87CEEB',
      categories: ['View All', 'Create New'],
    },
    {
      title: 'System Users',
      description: 'create and manage system users(employees, managers & admins)',
      color: '#FFD700',
      categories: ['View All', 'Create New'],
    },
    {
      title: 'Customers',
      description: 'Manage all registered Customers',
      color: '#98FB98',
      categories: ['View All', 'Create New'],
    },
  ];

  const [selectedCollection, setSelectedCollection] = useState<Collection | null>(null);

  const handleCardClick = (collection: Collection) => {
    setSelectedCollection(collection);
  };

  const handleViewItemsClick = (category: string) => {
    if (selectedCollection) {
      const collectionPath = selectedCollection.title.toLowerCase().replace(/\s/g, '-');
      const categoryPath = category.toLowerCase().replace(/\s/g, '-');
      const path = `/${collectionPath}/${categoryPath}`;
      navigate(path);
    }
  };
  

  // const handleViewItemsClick = (category: string) => {
  //   if (selectedCollection) {
  //     navigate(`/services-view/${selectedCollection.title}/${category}`);
  //   }
  // };

  return (
    <Container fluid className="mt-3 service-container">
      <h1 className="text-center mb-4">Account(s) Management</h1>

      <div className="card-container-services">
        {collections.map((collection, index) => (
          <Card
            key={index}
            className="custom-card-services"
            style={{ backgroundColor: collection.color, cursor: 'pointer' }}
            onClick={() => handleCardClick(collection)}
          >
            <Card.Body>
              <Card.Title>{collection.title}</Card.Title>
              <hr className="card-line" />
              <Card.Text>{collection.description}</Card.Text>
            </Card.Body>
          </Card>
        ))}
      </div>

      {selectedCollection && (
        <div className="datatable-container">
          <h2>{selectedCollection.title}</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Environment</th>
              </tr>
            </thead>
            <tbody>
              {selectedCollection.categories.map((category, index) => (
                <tr key={index}>
                  <Button className="accounts-nav-btns" variant="warning" onClick={() => handleViewItemsClick(category)}>
                  {category}
                  </Button>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </Container>
  );
};

export default Services;
