import '../assets/dashboard.css';
// @ts-ignore
import FooterFile from '../components/FooterFile';
// Define the AboutContent component
const AboutContent: React.FC = () => {
  return (
    <div className="dashboard-content">
      
      <section className="bio-section">
      <div className="bio-content">
        <div className="text-description">
          <h3 className="public-title">About Biashara Web </h3>
                    <p>
  

  

Welcome to Biashara Web, your trusted partner in digital transformation. At Biashara Web, we specialize in empowering businesses of all sizes to thrive in the digital age by providing innovative, user-friendly, and cost-effective solutions tailored to your specific needs. 

  
</p>
        </div>
        {/* <div className="video-banner">
          <video width="100%" height="100%" controls autoPlay loop muted>
            <source src={bioVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div> */}
      </div>
    </section>
      <hr></hr>
<FooterFile />
    </div>
  );
};

export default AboutContent;
