import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_BASE_URL, API_ENDPOINTS } from '../../my-api/apiConfig';
import { useAuth } from '../../AuthContext';

interface AgeGroup {
  id: number;
  age_group_title: string;
  age_group_range: string;
}

const AgeSetManagement: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const accessToken = localStorage.getItem('accessToken');

  const [ageGroups, setAgeGroups] = useState<AgeGroup[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchAgeGroups = async () => {
      try {
        console.log("API_BASE_URL:", API_BASE_URL);
        console.log("API_ENDPOINT:", API_ENDPOINTS.AUTH_AGE_SET);
        console.log("Access Token:", accessToken);

        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.AUTH_AGE_SET}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          console.log("Fetched Data:", data);
          if (data.age_groups && Array.isArray(data.age_groups)) {
            setAgeGroups(data.age_groups);
          } else {
            console.error('Unexpected data structure:', data);
          }
        } else {
          console.error('Error fetching age groups:', response.statusText);
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };

    fetchAgeGroups();
  }, [accessToken]);

  const handleEdit = (categoryId: number) => {
    navigate(`/edit-age-group/${categoryId}`);
  };

  const handleDelete = async (categoryId: number) => {
    try {
      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.AUTH_AGE_SET}/${categoryId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        setSuccessMessage(`Age group with ID ${categoryId} deleted successfully`);
        setAgeGroups(ageGroups.filter(category => category.id !== categoryId));
        window.location.reload(); // Refresh the page after a successful delete
      } else {
        console.error(`Error deleting age group with ID ${categoryId}`);
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  const filteredAgeGroups = ageGroups.filter((category) =>
    category.age_group_title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentAgeGroups = filteredAgeGroups.slice(indexOfFirstRecord, indexOfLastRecord);

  const handleRecordsPerPageChange = (value: number) => {
    setRecordsPerPage(value);
    setCurrentPage(1);
  };

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div className="table-container">
      <h2>Product Age-Set Management</h2>
      <div className="role-buttons">
        <Link to="/dashboard">
          <button className="back-button">
            <i className="fas fa-arrow-left"></i>Back
          </button>
        </Link>
        <Link to="/age-group/create-new">
          <button className="add-button">
            <i className="fas fa-plus"></i> Add
          </button>
        </Link>
      </div>
      {successMessage && <div className="success-message">{successMessage}</div>}
      <div className="role-search-form">
        <input
          type="text"
          placeholder="Search age groups..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button className="search-button" onClick={() => console.log('Search')}>
          <i className="fas fa-search"></i>
        </button>
      </div>
      <div className="records-per-page">
        <label>Filter:</label>
        <select
          value={recordsPerPage}
          onChange={(e) => handleRecordsPerPageChange(Number(e.target.value))}
        >
          {[5, 10, 15].map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <table className="custom-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Range</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentAgeGroups.map((category) => (
            <tr key={category.id}>
              <td>{category.age_group_title}</td>
              <td>{category.age_group_range}</td>
              <td className="action-btn-wrapper">
                <button className="action-button" onClick={() => handleEdit(category.id)}>
                  <i className="fas fa-edit"></i>
                </button>
                <button className="action-button" onClick={() => handleDelete(category.id)}>
                  <i className="fas fa-trash-alt"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        {Array.from({ length: Math.ceil(filteredAgeGroups.length / recordsPerPage) }).map(
          (_, index) => (
            <button key={index + 1} onClick={() => paginate(index + 1)}>
              {index + 1}
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default AgeSetManagement;
