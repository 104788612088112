import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_BASE_URL, API_ENDPOINTS } from '../../my-api/apiConfig';
// import '../../assets/css/RolePermissions.css'; 

interface ProductCategory {
  id: number;
  product_category_name: string;
}

const AddUserAccounts = () => {
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    product_sub_category_name: '',
    product_sub_category_description: '',
    product_category_id: '',  // Added this field for the selected category
  });

  const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);

  useEffect(() => {
    const fetchProductCategories = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.AUTH_PRODUCT_CATEGORIES}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Fetched product categories:', data);  // Debug log

          // Assuming the data structure is { product_categories: [...] }
          if (data.product_categories && Array.isArray(data.product_categories)) {
            setProductCategories(data.product_categories);
          } else {
            console.error('Unexpected data structure:', data);
          }
        } else {
          console.error('Error fetching product categories:', response.statusText);
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };

    fetchProductCategories();
  }, [accessToken]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.AUTH_PRODUCT_SUB_CATEGORIES}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const userData = await response.json();
        console.log('Product sub-category added successfully', userData);

        // Redirect to the view-all product categories page
        navigate('/products/product-sub-categories');
      } else {
        console.error('Error adding product sub-category');
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  return (
    <div className="add-role-container">
      <h2>Add New Product Sub Category</h2>
      <Link to="/products/product-sub-categories" className="role-back-button">
        <i className="fas fa-arrow-left"></i> Back
      </Link>
      <form className="add-role-form" onSubmit={handleSubmit}>
        <label htmlFor="product_sub_category_name">Product Sub Category Name:</label>
        <input
          type="text"
          id="product_sub_category_name"
          placeholder="Product Sub Category Name"
          value={formData.product_sub_category_name}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormData({ ...formData, product_sub_category_name: e.target.value })
          }
        />

        <label htmlFor="product_sub_category_description">Product Sub Category Description:</label>
        <textarea
          id="product_sub_category_description"
          placeholder="Description"
          value={formData.product_sub_category_description}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            setFormData({ ...formData, product_sub_category_description: e.target.value })
          }
        />

        <label htmlFor="product_category_id">Product Category:</label>
        <select
          id="product_category_id"
          value={formData.product_category_id}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            setFormData({ ...formData, product_category_id: e.target.value })
          }
        >
          <option value="">Select a category</option>
          {productCategories.map(category => (
            <option key={category.id} value={category.id}>
              {category.product_category_name}
            </option>
          ))}
        </select>

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default AddUserAccounts;
