// data.ts
import { API_BASE_URL, API_ENDPOINTS } from '../my-api/apiConfig';

export interface Option {
  value: string;
  label: string;
}

export const fetchProductCategories = async (): Promise<Option[]> => {
  try {
    const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.PRODUCT_CATEGORIES}`);
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Failed to fetch product categories');
    }

    const sortedCategories = data.product_categories.sort((a: any, b: any) => a.id - b.id);

    // Log the sorted categories
    console.log("Sorted Categories:", sortedCategories);

    return sortedCategories.map((category: any) => ({
      value: String(category.id),
      label: category.product_category_name,
    }));
  } catch (error) {
    console.error('Error fetching product categories:', error);
    return [{ value: '', label: 'Select' }];
  }
};

export const fetchProductSubCategories = async (): Promise<Option[]> => {
  try {
    const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.PRODUCT_SUB_CATEGORIES}`);
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Failed to fetch product sub-categories');
    }

    const sortedSubCategories = data.product_sub_categories.sort((a: any, b: any) => a.id - b.id);

    // Log the sorted sub-categories
    console.log("Sorted Sub-Categories:", sortedSubCategories);

    return sortedSubCategories.map((subCategory: any) => ({
      value: String(subCategory.id),
      label: subCategory.product_sub_category_name,
    }));
  } catch (error) {
    console.error('Error fetching product sub-categories:', error);
    return [{ value: '', label: 'Select' }];
  }
};

export const fetchProductTypes = async (): Promise<Option[]> => {
  try {
    const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.PRODUCT_TYPES}`);
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Failed to fetch product types');
    }

    const sortedProductTypes = data.product_types.sort((a: any, b: any) => a.id - b.id);

    // Log the sorted product types
    console.log("Sorted Product Types:", sortedProductTypes);

    return sortedProductTypes.map((productType: any) => ({
      value: String(productType.id),
      label: productType.product_type_name,
    }));
  } catch (error) {
    console.error('Error fetching product types:', error);
    return [{ value: '', label: 'Select' }];
  }
};

export const fetchProductBrands = async (): Promise<Option[]> => {
  try {
    const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.PRODUCT_BRAND}`);
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Failed to fetch product brands');
    }

    const sortedBrands = data.product_brands.sort((a: any, b: any) => a.id - b.id);

    // Log the sorted brands
    console.log("Sorted Brands:", sortedBrands);

    return sortedBrands.map((brand: any) => ({
      value: String(brand.id),
      label: brand.product_brand_name,
    }));
  } catch (error) {
    console.error('Error fetching product brands:', error);
    return [{ value: '', label: 'Select' }];
  }
};


export const fetchAgeGroups = async (): Promise<Option[]> => {
	try {
	  const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.AGE_SET}`);
	  const data = await response.json();
  
	  if (!response.ok) {
		throw new Error(data.message || 'Failed to fetch age groups');
	  }
  
	  const sortedAgeGroups = data.age_groups.sort((a: any, b: any) => a.id - b.id);
  
	  console.log("Sorted Age Groups:", sortedAgeGroups);
  
	  return sortedAgeGroups.map((ageGroup: any) => ({
		value: String(ageGroup.id),
		label: ageGroup.age_group_title,
	  }));
	} catch (error) {
	  console.error('Error fetching age groups:', error);
	  return [{ value: '', label: 'Select' }];
	}
  };
