import React, { useState } from 'react';
import { Button, Form, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import { FaMoneyBillWave, FaMobile, FaPaypal } from 'react-icons/fa';

const CheckoutPageDashboard = () => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | null>(null);
  const [deliveryDetails, setDeliveryDetails] = useState<string>('');

  const handlePaymentSelect = (paymentMethod: string) => {
    setSelectedPaymentMethod(paymentMethod);
  };

  const handleDeliveryDetailsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeliveryDetails(event.target.value);
  };

  const handleCheckout = () => {
    // Implement transaction completion logic based on the selected payment method
    console.log('Selected Payment Method:', selectedPaymentMethod);
    console.log('Delivery Details:', deliveryDetails);
    // Redirect user to a thank you page or order summary page
  };

  return (
    <div className="checkout-page">
      <h2>Checkout</h2>
      <p>Select a payment method:</p>

      <div className="payment-method-buttons">
      {/* // Render the payment method buttons with toggled state based on selectedPaymentMethod */}
<Button
  variant={selectedPaymentMethod === 'CashOnDelivery' ? 'primary' : 'outline-primary'}
  onClick={() => handlePaymentSelect('CashOnDelivery')}
>
  <FaMoneyBillWave />
  {' '}
  Cash on Delivery
</Button>

<Button
  variant={selectedPaymentMethod === 'LipaNaMpesa' ? 'primary' : 'outline-primary'}
  onClick={() => handlePaymentSelect('LipaNaMpesa')}
>
  <FaMobile />
  {' '}
  Lipa na M-Pesa
</Button>

<Button
  variant={selectedPaymentMethod === 'PayPal' ? 'primary' : 'outline-primary'}
  onClick={() => handlePaymentSelect('PayPal')}
>
  <FaPaypal />
  {' '}
  PayPal
</Button>

      </div>

      {/* Render the appropriate payment method form */}
      {selectedPaymentMethod === 'CashOnDelivery' && (
        <div>
          <h3>Delivery Details</h3>
          <Form>
            <FormGroup>
              <FormLabel>Address:</FormLabel>
              <FormControl type="text" value={deliveryDetails} onChange={handleDeliveryDetailsChange} />
            </FormGroup>
            <Button onClick={handleCheckout}>Complete Order</Button>
          </Form>
        </div>
      )}

      {selectedPaymentMethod === 'LipaNaMpesa' && (
        <div>
          <h3>Delivery Details</h3>
          <Form>
            <FormGroup>
              <FormLabel>Mobile Number:</FormLabel>
              <FormControl type="text" value={deliveryDetails} onChange={handleDeliveryDetailsChange} />
            </FormGroup>
            <Button onClick={handleCheckout}>Complete Order</Button>
          </Form>
        </div>
      )}

      {selectedPaymentMethod === 'PayPal' && (
        <div>
          <h3>PayPal Payment Form</h3>
          {/* Add PayPal payment form here */}
          <Button onClick={handleCheckout}>Complete Order</Button>
        </div>
      )}
    </div>
  );
};

export default CheckoutPageDashboard;
