import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { API_BASE_URL, API_ENDPOINTS } from '../../my-api/apiConfig';

// Define the interface for a ProductBrand
interface ProductBrand {
  id: number;
  product_brand_name: string;
  product_brand_description: string;
}

const AddOrEditProductBrand: React.FC = () => {
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const { brandId } = useParams<{ brandId: string }>();

  const [formData, setFormData] = useState<ProductBrand>({
    id: 0,
    product_brand_name: '',
    product_brand_description: '',
  });

  const [isLoading, setIsLoading] = useState<boolean>(!!brandId);

  useEffect(() => {
    if (brandId) {
      const fetchBrandData = async () => {
        try {
          const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.AUTH_PRODUCT_BRANDS}/${brandId}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (response.ok) {
            const data = await response.json();
            const productBrand = data.product_brand;
            setFormData({
              id: productBrand.id,
              product_brand_name: productBrand.product_brand_name || '',
              product_brand_description: productBrand.product_brand_description || '',
            });
          } else {
            console.error('Error fetching brand data', response.statusText);
          }
        } catch (error) {
          console.error('API request failed', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchBrandData();
    } else {
      setIsLoading(false);
    }
  }, [brandId, accessToken]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const url = brandId
        ? `${API_BASE_URL}${API_ENDPOINTS.AUTH_PRODUCT_BRANDS}/${brandId}`
        : `${API_BASE_URL}${API_ENDPOINTS.AUTH_PRODUCT_BRANDS}`;

      const response = await fetch(url, {
        method: brandId ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const userData = await response.json();
        console.log('Product brand added/updated successfully', userData);
        navigate('/products/product-brands');
      } else {
        console.error('Error adding/updating product brand', response.statusText);
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  return (
    <div className="add-role-container">
      <h2>{brandId ? 'Edit Product Brand' : 'Add New Product Brand'}</h2>
      <Link to="/products/product-brands" className="role-back-button">
        <i className="fas fa-arrow-left"></i> Back
      </Link>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <form className="add-role-form" onSubmit={handleSubmit}>
          <label htmlFor="product_brand_name">Product Brand Name:</label>
          <input
            type="text"
            id="product_brand_name"
            placeholder="Product Brand Name"
            value={formData.product_brand_name}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFormData({ ...formData, product_brand_name: e.target.value })
            }
          />
          <label htmlFor="product_brand_description">Product Brand Description:</label>
          <textarea
            id="product_brand_description"
            placeholder="Description"
            value={formData.product_brand_description}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setFormData({ ...formData, product_brand_description: e.target.value })
            }
          />
          <button type="submit">Submit</button>
        </form>
      )}
    </div>
  );
};

export default AddOrEditProductBrand;
