import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_BASE_URL, API_ENDPOINTS } from '../../my-api/apiConfig';
import { useAuth } from '../../AuthContext';

interface ProductCategory {
  id: number;
  product_category_name: string;
  product_category_description: string;
}

interface ProductSubCategory {
  id: number;
  product_category_id: number;
  product_sub_category_name: string;
  product_sub_category_description: string;
  product_category?: ProductCategory; // Make this optional
}

const ProductCategories: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const accessToken = localStorage.getItem('accessToken');

  const [productSubCategories, setProductSubCategories] = useState<ProductSubCategory[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchProductSubCategories = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.AUTH_PRODUCT_SUB_CATEGORIES}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          if (data.product_sub_categories && Array.isArray(data.product_sub_categories)) {
            setProductSubCategories(data.product_sub_categories);
          } else {
            console.error('Unexpected data structure:', data);
          }
        } else {
          console.error('Error fetching product sub-categories:', response.statusText);
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };

    fetchProductSubCategories();
  }, [accessToken]);

  const handleEdit = (subCategoryId: number) => {
    navigate(`/edit-product-sub-category/${subCategoryId}`);
  };

  const handleDelete = async (subCategoryId: number) => {
    try {
      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.AUTH_PRODUCT_SUB_CATEGORIES}/${subCategoryId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        setSuccessMessage(`Product sub-category with ID ${subCategoryId} deleted successfully`);
        setProductSubCategories(productSubCategories.filter(subCategory => subCategory.id !== subCategoryId));
        window.location.reload(); // Refresh the page after a successful delete
      } else {
        console.error(`Error deleting product sub-category with ID ${subCategoryId}`);
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  const filteredProductSubCategories = productSubCategories.filter((subCategory) =>
    subCategory.product_sub_category_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentProductSubCategories = filteredProductSubCategories.slice(indexOfFirstRecord, indexOfLastRecord);

  const handleRecordsPerPageChange = (value: number) => {
    setRecordsPerPage(value);
    setCurrentPage(1);
  };

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div className="table-container">
      <h2>Product Sub-Category Management</h2>
      <div className="role-buttons">
        <Link to="/dashboard">
          <button className="back-button">
            <i className="fas fa-arrow-left"></i> Back
          </button>
        </Link>
        <Link to="/product-sub-category/create-new">
          <button className="add-button">
            <i className="fas fa-plus"></i> Add
          </button>
        </Link>
      </div>
      {successMessage && <div className="success-message">{successMessage}</div>}
      <div className="role-search-form">
        <input
          type="text"
          placeholder="Search product sub-categories..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button className="search-button" onClick={() => console.log('Search')}>
          <i className="fas fa-search"></i>
        </button>
      </div>
      <div className="records-per-page">
        <label>filter:</label>
        <select
          value={recordsPerPage}
          onChange={(e) => handleRecordsPerPageChange(Number(e.target.value))}
        >
          {[5, 10, 15].map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <table className="custom-table">
        <thead>
          <tr>
            <th>Sub-Category Name</th>
            <th>Sub-Category Description</th>
            <th>Category Name</th>
            <th>Category Description</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentProductSubCategories.map((subCategory) => (
            <tr key={subCategory.id}>
              <td>{subCategory.product_sub_category_name}</td>
              <td>{subCategory.product_sub_category_description}</td>
              <td>{subCategory.product_category?.product_category_name ?? 'N/A'}</td>
              <td>{subCategory.product_category?.product_category_description ?? 'N/A'}</td>
              <td className="action-btn-wrapper">
                <button className="action-button" onClick={() => handleEdit(subCategory.id)}>
                  <i className="fas fa-edit"></i>
                </button>
                <button className="action-button" onClick={() => handleDelete(subCategory.id)}>
                  <i className="fas fa-trash-alt"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        {Array.from({ length: Math.ceil(filteredProductSubCategories.length / recordsPerPage) }).map(
          (_, index) => (
            <button key={index + 1} onClick={() => paginate(index + 1)}>
              {index + 1}
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default ProductCategories;
