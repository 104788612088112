import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { API_BASE_URL, API_ENDPOINTS } from '../../my-api/apiConfig';

// Define the interface for a ProductCategory
interface ProductCategory {
  id: number;
  age_group_title: string;
  age_group_range: string;
}

const AddUserAccounts: React.FC = () => {
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const { ageSetId } = useParams<{ ageSetId: string }>();

  const [formData, setFormData] = useState<ProductCategory>({
    id: 0,
    age_group_title: '',
    age_group_range: '',
  });

  const [isLoading, setIsLoading] = useState<boolean>(!!ageSetId);

  useEffect(() => {
    if (ageSetId) {
      const fetchCategoryData = async () => {
        try {
          const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.AUTH_AGE_SET}/${ageSetId}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (response.ok) {
            const data = await response.json();
            const productCategory = data.age_group;  // Adjusting to match the response structure
            setFormData({
              id: productCategory.id,
              age_group_title: productCategory.age_group_title || '',
              age_group_range: productCategory.age_group_range || '',
            });
          } else {
            console.error('Error fetching category data', response.statusText);
          }
        } catch (error) {
          console.error('API request failed', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchCategoryData();
    } else {
      setIsLoading(false);
    }
  }, [ageSetId, accessToken]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const url = ageSetId
        ? `${API_BASE_URL}${API_ENDPOINTS.AUTH_AGE_SET}/${ageSetId}`
        : `${API_BASE_URL}${API_ENDPOINTS.AUTH_AGE_SET}`;

      const response = await fetch(url, {
        method: ageSetId ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const userData = await response.json();
        console.log('Product category added/updated successfully', userData);
        navigate('/products/product-age-set');
      } else {
        console.error('Error adding/updating product category', response.statusText);
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  return (
    <div className="add-role-container">
      <h2>{ageSetId ? 'Edit Age-Set' : 'Add New Age-Set'}</h2>
      <Link to="/products/product-age-set" className="role-back-button">
        <i className="fas fa-arrow-left"></i> Back
      </Link>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <form className="add-role-form" onSubmit={handleSubmit}>
          <label htmlFor="age_group_title">Title Name:</label>
          <input
            type="text"
            id="age_group_title"
            placeholder="Title Name"
            value={formData.age_group_title}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFormData({ ...formData, age_group_title: e.target.value })
            }
          />
          <label htmlFor="age_group_range">Range Description:</label>
          <textarea
            id="age_group_range"
            placeholder="Description"
            value={formData.age_group_range}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setFormData({ ...formData, age_group_range: e.target.value })
            }
          />
          <button type="submit">Submit</button>
        </form>
      )}
    </div>
  );
};

export default AddUserAccounts;
