
import React, { useState } from 'react';
import { Container, Card, Button, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

// Define interfaces for Ad and AdWithDetails
interface Ad {
  id: number;
  title: string;
  description: string;
  image_url?: string | null;
  video_url?: string | null;
  category_id: number;
  subcategory_id: number;
  user_id: number;
  category_name: string;
  subcategory_name: string;
  user_full_name: string;
  ad_type: string;
  price: number | null;
  default_image: { id: number; image_url: string } | null;
  likes: number;
  dislikes: number;
  views: number;
  badge: string;
}

export interface AdWithDetails extends Ad {}

interface Collection {
  title: string;
  description: string;
  color: string;
  categories: string[];
}

const Services: React.FC = () => {
  const navigate = useNavigate();
  const collections: Collection[] = [
    {
      title: 'Advertisement',
      description: 'Click to manage adverts',
      color: '#87CEEB',
      categories: ['View All', 'Create New'],
    },
    {
      title: 'Services',
      description: 'Click to manage Services',
      color: '#FFD700',
      categories: ['View All', 'Create New'],
    },
    {
      title: 'Products',
      description: 'Click to manage Products',
      color: '#98FB98',
      categories: ['Product Listing', 'Product Categories', 'Product Sub-Categories', 'Product Brands', 'Product Type', 'Product Age-Set'],
    },
  ];

  const [selectedCollection, setSelectedCollection] = useState<Collection | null>(null);

  const handleCardClick = (collection: Collection) => {
    setSelectedCollection(collection);
  };

  const handleViewItemsClick = (category: string) => {
    if (selectedCollection) {
      const collectionPath = selectedCollection.title.toLowerCase().replace(/\s/g, '-');
      const categoryPath = category.toLowerCase().replace(/\s/g, '-');
      const path = `/${collectionPath}/${categoryPath}`;
      navigate(path);
    }
  };
  

  // const handleViewItemsClick = (category: string) => {
  //   if (selectedCollection) {
  //     navigate(`/services-view/${selectedCollection.title}/${category}`);
  //   }
  // };

  return (
    <Container fluid className="mt-3 service-container">
      <h1 className="text-center mb-4">Dashboard</h1>

      <div className="card-container-services">
        {collections.map((collection, index) => (
          <Card
            key={index}
            className="custom-card-services"
            style={{ backgroundColor: collection.color, cursor: 'pointer' }}
            onClick={() => handleCardClick(collection)}
          >
            <Card.Body>
              <Card.Title>{collection.title}</Card.Title>
              <hr className="card-line" />
              <Card.Text>{collection.description}</Card.Text>
            </Card.Body>
          </Card>
        ))}
      </div>

      {selectedCollection && (
        <div className="datatable-container">
          <h2>{selectedCollection.title}</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Menu</th>
              </tr>
            </thead>
            <tbody>
              {selectedCollection.categories.map((category, index) => (
                <tr key={index}>
                  <Button className="accounts-nav-btns" variant="warning" onClick={() => handleViewItemsClick(category)}>
                  {category}
                  </Button>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </Container>
  );
};

export default Services;
