import React, { useEffect, useState } from 'react'; // Import React
import '../components/publicpage/styles.css'; // Import CSS file
// @ts-ignore
import {
	Button
} from 'react-bootstrap';
import { useAuth } from '../AuthContext';
import axios from 'axios';
import { API_BASE_URL, API_ENDPOINTS } from '../my-api/apiConfig';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight, FaEye, FaShoppingCart } from 'react-icons/fa';
import Select from 'react-select';
import { fetchProductCategories, fetchProductSubCategories, fetchProductTypes, fetchProductBrands, fetchAgeGroups, Option } from './data';

const SpaBarberPage = () => {
  const accessToken = localStorage.getItem('accessToken');
  const { user } = useAuth();
  const [setUserDetails] = useState<any | null>(null);
  const navigate = useNavigate();

  const genderOptions = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
  ];

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = window.innerWidth <= 768 ? 5 : 10; // Adjust items per page for smaller screens
  
    // Change page
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    // const [options, setOptions] = useState<Option[]>([]);
    const [options, setOptions] = useState<Option[]>([{ value: '', label: 'Select' }]);

    const [selectedOption, setSelectedOption] = useState<Option | null>(null);
    const [subCategoryOptions, setSubCategoryOptions] = useState<Option[]>([]);
    const [productTypeOptions, setProductTypeOptions] = useState<Option[]>([]);
    const [productBrandOptions, setProductBrandOptions] = useState<Option[]>([]);
    const [ageSetOptions, setAgeSetOptions] = useState<Option[]>([]);
    const [selectedGender, setSelectedGender] = useState<Option | null>(null);
    const [products, setProducts] = useState([]);
    const [productItems, setProductItems] = useState([]);

  
    useEffect(() => {
      const getOptions = async () => {
        const fetchedOptions = await fetchProductCategories();
        setOptions(fetchedOptions);
        // if (fetchedOptions) {
        //   setSelectedOption(fetchedOptions[0]);
        // }
      };
  
      getOptions();
    }, []);
    const handleChange = async (selected: Option | null) => {
      setSelectedOption(selected);
      if (selected) {
        console.log("Selected product category - Value:", selected.value, "Label:", selected.label);
        try {
          const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.PRODUCT_CATEGORY_SUB_CATEGORY}/${selected.value}`);
          const data = await response.json();
      
          if (!response.ok) {
            throw new Error(data.message || 'Failed to fetch product sub-categories');
          }
      
          const sortedSubCategories = data.product_sub_categories.sort((a: any, b: any) => a.id - b.id);
      
          // Log the sorted sub-categories
          console.log("Sorted Sub-Categories:", sortedSubCategories);
      
          setSubCategoryOptions(sortedSubCategories.map((subCategory: any) => ({
            value: String(subCategory.id),
            label: subCategory.product_sub_category_name,
          })));
        } catch (error) {
          console.error('Error fetching product sub-categories:', error);
          setSubCategoryOptions([{ value: '', label: 'Select' }]);
        }
      } else {
        console.log("No option selected");
        setSubCategoryOptions([{ value: '', label: 'Select' }]);
      }
    };
    

      // Use fetchProductSubCategories to fetch sub-categories
  useEffect(() => {
    const getSubCategories = async () => {
      const fetchedSubCategories = await fetchProductSubCategories();
      setSubCategoryOptions(fetchedSubCategories);
    };

    getSubCategories();
  }, []);

  useEffect(() => {
    const getProductTypes = async () => {
      const fetchedProductTypes = await fetchProductTypes();
      setProductTypeOptions(fetchedProductTypes);
    };
    getProductTypes();
  }, []);

  useEffect(() => {
    const getProductBrands = async () => {
      const fetchedProductBrands = await fetchProductBrands();
      setProductBrandOptions(fetchedProductBrands);
    };
    getProductBrands();
  }, []);

  useEffect(() => {
    const getAgeGroups = async () => {
      const fetchedAgeGroups = await fetchAgeGroups();
      setAgeSetOptions(fetchedAgeGroups);
    };
    getAgeGroups();
  }, []);



  useEffect(() => {
    const fetchProductItems = async () => {
      try {
        const response = await axios.get(API_BASE_URL + API_ENDPOINTS.PRODUCT_LIST_ITEMS);
        if (response.data && response.data.product_items) {
          setProductItems(response.data.product_items);
        } else {
          console.error('Invalid product data in the server response');
        }
      } catch (error) {
        console.error('Failed to fetch product items:', error);
      }
    };
  
    fetchProductItems();
  }, []);
  

  useEffect(() => {
    const handleMenuToggle = () => {
      const navModal = document.getElementById('navModal');
      if (navModal) {
        if (navModal.style.display === "none" || navModal.style.display === "") {
          navModal.style.display = "flex";
        } else {
          navModal.style.display = "none";
        }
      }
    };

    const handleCloseModal = () => {
      const navModal = document.getElementById('navModal');
      if (navModal) {
        navModal.style.display = "none";
      }
    };

    const handleActivateLinks = () => {
      const navLinks = document.querySelectorAll('.nav-links a');
      navLinks.forEach(link => {
        link.addEventListener('click', () => {
          navLinks.forEach(innerLink => {
            innerLink.classList.remove('active');
          });
          link.classList.add('active');
        });
      });
    };

    // Add event listeners when component mounts
    const menuToggle = document.getElementById('menuToggle');
    const closeModal = document.getElementById('closeModal');

    if (menuToggle) {
      menuToggle.addEventListener('click', handleMenuToggle);
    }

    if (closeModal) {
      closeModal.addEventListener('click', handleCloseModal);
    }

    handleActivateLinks();

    // Cleanup
    return () => {
      if (menuToggle) {
        menuToggle.removeEventListener('click', handleMenuToggle);
      }
      if (closeModal) {
        closeModal.removeEventListener('click', handleCloseModal);
      }
      const navLinks = document.querySelectorAll('.nav-links a');
      navLinks.forEach(link => {
        link.removeEventListener('click', () => {});
      });
    };
  }, []); // Empty dependency array means this effect runs only once after initial render

useEffect(() => {
  const fetchUserDetails = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}${API_ENDPOINTS.SINGLE_USER}/${user?.id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.data && response.data.user) {
        setUserDetails(response.data.user);
      } else {
        console.error('Invalid user data in the server response');
      }
    } catch (error) {
      console.error('Failed to fetch user details:', error);
    }
  };

  if (user) {
    fetchUserDetails();
  }
}, [user, accessToken]);


const handlePreviewClick = (productId: number) => {
  navigate(`/preview-page/${productId}`);
};

    return (
    <>
      {/* product section */}
      <section className="product-section">
      {/* product sudebar */}
        <div className="product-sidebar">
          {/* product categoris menu */}
          <div className="product-categories-menu">
            <h3>Products</h3>
          <ul>
            <li>
              <span>Filter By Category</span>

              <Select
                className="select2 z-3"
                options={options}
                value={selectedOption}
                onChange={handleChange}
              />
               </li>
          </ul>
          </div>

        </div>

        {/* product main bar */}
        <div className="Product-mainbar">
        <div className="product-category-nav">     
    <span><FaChevronLeft/></span> 
    <h3 className="product-category-title">{selectedOption ? selectedOption.label : "All Products"}</h3>
    <span><FaChevronRight/></span>
  </div>
          <div className="product-sub-categories-menu">
            <ul>
              <li>
                <div className="product-sub-category-title">
                  <span>Filter by sub category</span>
                  <Select className="select2 z-3" options={subCategoryOptions} />
                  </div>
               
                <div className="product-menu">
                  {/* <div className="product-menu-search-filter">
                    <div className="product-search-item">
                      <span>Brands</span>
                      <Select
                      options={productBrandOptions}
                      placeholder="Select or Type Product Brand"
                      className="mb-3"
                    />
                    </div>
                    <div className="product-search-item">
                    <span>Types</span>
                    <Select className="select2 z-3" options={productTypeOptions} />
                    </div>
                    <div className="product-search-item">
                    <span>Age-Set</span>
                    <Select className="select2 z-3" options={ageSetOptions} />
                    </div>
                    <div className="product-search-item">
                    <span>Gender</span>
                    <Select
                    value={selectedGender}
                    onChange={setSelectedGender}
                    options={genderOptions}
                    placeholder="Select Gender"
                  />
                    </div>
                  </div> */}

                  <div className="product-list-container">
                  {productItems.map((product: any, index: number) => (
                    <div key={index} className="product-card">
                      <div className="product-image">
                        {product.images.length > 0 ? (
                          <img src={API_BASE_URL + product.images[0].path} alt={product.product_name} />
                        ) : (
                          <img src="placeholder_image_url" alt="Placeholder" />
                        )}
                      </div>
                      <div className="product-content">
                        <h3>{product.product_name}</h3>
                        {/* <p>{product.product_description}</p> */}
                        <p>Price: {product.product_price}</p>
                        <p>Quantity: {product.product_quantity}</p>
                      </div>
                      <div className="product-btns">
                      <button onClick={() => handlePreviewClick(product.id)}><FaEye /></button>
                        {/* <button><FaShoppingCart /></button> */}
                      </div>
                    </div>
  ))}
</div>


                  
                  <div className="product-pagenation">
        {Array.from({ length: Math.ceil(products.length / itemsPerPage) }, (_, index) => index + 1).map((pageNumber) => (
          <Button key={pageNumber} variant={pageNumber === currentPage ? "primary" : "light"} onClick={() => paginate(pageNumber)}>
            {pageNumber}
          </Button>
        ))}
      </div>


                </div>

              </li>
            </ul>

          </div>
        </div>

      </section>
      <script src="publicpage/script.js"></script>
    </>
  );
};

export default SpaBarberPage;

