// src/components/UserManagement/AddUserAccounts.tsx
import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_BASE_URL, API_ENDPOINTS } from '../../my-api/apiConfig';
// import '../../assets/css/RolePermissions.css'; 

const AddUserAccounts = () => {
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    age_group_title: '',
    age_group_range: '',
  });

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.AUTH_AGE_SET}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const userData = await response.json();
        console.log('Product category added successfully', userData);

        // Redirect to the view-all product categories page
        navigate('/products/product-age-set');
      } else {
        console.error('Error adding product category');
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  return (
    <div className="add-role-container">
      <h2>Add New Age Set</h2>
      <Link to="/products/product-age-set" className="role-back-button">
        <i className="fas fa-arrow-left"></i> Back
      </Link>
      <form className="add-role-form" onSubmit={handleSubmit}>
        <label htmlFor="age_group_title">Age-Set Title:</label>
        <input
          type="text"
          id="age_group_title"
          placeholder="Title Name"
          value={formData.age_group_title}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormData({ ...formData, age_group_title: e.target.value })
          }
        />

        <label htmlFor="age_group_range">Age-Set Range:</label>
        <textarea
          id="age_group_range"
          placeholder="Range Description"
          value={formData.age_group_range}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            setFormData({ ...formData, age_group_range: e.target.value })
          }
        />

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default AddUserAccounts;
