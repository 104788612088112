// src/components/UserManagement/AddUserAccounts.tsx
import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_BASE_URL, API_ENDPOINTS } from '../../my-api/apiConfig';
// import '../../assets/css/RolePermissions.css'; 

const AddUserAccounts = () => {
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    product_brand_name: '',
    product_brand_description: '',
  });

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.AUTH_PRODUCT_BRANDS}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const userData = await response.json();
        console.log('Product category added successfully', userData);

        // Redirect to the view-all product categories page
        navigate('/products/product-brands');
      } else {
        console.error('Error adding product category');
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  return (
    <div className="add-role-container">
      <h2>Add New Brand</h2>
      <Link to="/products/product-brands" className="role-back-button">
        <i className="fas fa-arrow-left"></i> Back
      </Link>
      <form className="add-role-form" onSubmit={handleSubmit}>
        <label htmlFor="product_brand_name">Product Brand Name:</label>
        <input
          type="text"
          id="product_brand_name"
          placeholder="Product Brand Name"
          value={formData.product_brand_name}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormData({ ...formData, product_brand_name: e.target.value })
          }
        />

        <label htmlFor="product_brand_description">Product Brand Description:</label>
        <textarea
          id="product_brand_description"
          placeholder="Description"
          value={formData.product_brand_description}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            setFormData({ ...formData, product_brand_description: e.target.value })
          }
        />

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default AddUserAccounts;
