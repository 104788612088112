// src/components/LoginForm.tsx
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../AuthContext'; // Import useAuth
import '../assets/LoginForm.css';
import logoImage from '../components/publicpage/images/biashara-logo.png';
import { API_BASE_URL, API_ENDPOINTS } from '../my-api/apiConfig';

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const { login } = useAuth(); // Use useAuth to access the authentication context

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
  
    try {
      const response = await axios.post(`${API_BASE_URL}${API_ENDPOINTS.LOGIN}`, {
        email,
        password,
      });
  
      const { message, user, access_token } = response.data;
      console.log(user);
      if (message === 'Login successful') {
        login(user, access_token); // Use login function from useAuth
  
        // Check if the user has roles and is not empty
        if (user.roles && user.roles.length > 0) {
          // Navigate to the dashboard path
          navigate('/dashboard');
        } else {
          // Navigate to auth-adverts path
          navigate('/auth-adverts');
        }
      } else {
        setErrorMessage('This User does not exist, check your details');
      }
    } catch (error: any) {
      console.error('Login failed:', error.message);
      setErrorMessage('An error occurred. Please try again later.');
    }
  };
  

  return (
    <div className="login-background-container">
      <div className="login-form-wrapper">
        <div className="login-form">
          <img src={logoImage} alt="Logo" className="logo" />
          <h3 style={{ color: '#ffffe', textAlign: 'center' }}>Login first to access resources</h3>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <div className="form-group">
            <input
             className='login-input'
              type="email"
              id="email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <input
            className='login-input'
              type="password"
              id="password"
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button className='login-button' type="submit" onClick={handleLogin}>
            Login
          </button>
         
        </div>
        <h3 style={{ color: '#ffffe', textAlign: 'center' }}>Create account if you don't have one</h3>
        <div className="login-nav-buttons">
  <Link to="/register" className='login-button'>
    Register
  </Link>

  
   <Link to="/welcomepage" className='login-button'>
    Go Back
  </Link>
</div>

        
      </div>
    </div>
  );
};

export default LoginForm;
