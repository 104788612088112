import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { API_BASE_URL, API_ENDPOINTS } from '../../my-api/apiConfig';

// Define the interface for a ProductCategory
interface ProductCategory {
  id: number;
  product_type_name: string;
  product_type_description: string;
}

const AddUserAccounts: React.FC = () => {
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const { typeId } = useParams<{ typeId: string }>();

  const [formData, setFormData] = useState<ProductCategory>({
    id: 0,
    product_type_name: '',
    product_type_description: '',
  });

  const [isLoading, setIsLoading] = useState<boolean>(!!typeId);

  useEffect(() => {
    if (typeId) {
      const fetchCategoryData = async () => {
        try {
          const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.AUTH_PRODUCT_TYPES}/${typeId}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (response.ok) {
            const data = await response.json();
            const productType = data.product_type;
            setFormData({
              id: productType.id,
              product_type_name: productType.product_type_name || '',
              product_type_description: productType.product_type_description || '',
            });
          } else {
            console.error('Error fetching category data', response.statusText);
          }
        } catch (error) {
          console.error('API request failed', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchCategoryData();
    } else {
      setIsLoading(false);
    }
  }, [typeId, accessToken]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const url = typeId
        ? `${API_BASE_URL}${API_ENDPOINTS.AUTH_PRODUCT_TYPES}/${typeId}`
        : `${API_BASE_URL}${API_ENDPOINTS.AUTH_PRODUCT_TYPES}`;

      const response = await fetch(url, {
        method: typeId ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const userData = await response.json();
        console.log('Product type added/updated successfully', userData);
        navigate('/products/product-type');
      } else {
        console.error('Error adding/updating product type', response.statusText);
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  return (
    <div className="add-role-container">
      <h2>{typeId ? 'Edit Product Type' : 'Add New Product Type'}</h2>
      <Link to="/products/product-type" className="role-back-button">
        <i className="fas fa-arrow-left"></i> Back
      </Link>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <form className="add-role-form" onSubmit={handleSubmit}>
          <label htmlFor="product_type_name">Product Type Name:</label>
          <input
            type="text"
            id="product_type_name"
            placeholder="Product Type Name"
            value={formData.product_type_name}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFormData({ ...formData, product_type_name: e.target.value })
            }
          />
          <label htmlFor="product_type_description">Product Type Description:</label>
          <textarea
            id="product_type_description"
            placeholder="Description"
            value={formData.product_type_description}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setFormData({ ...formData, product_type_description: e.target.value })
            }
          />
          <button type="submit">Submit</button>
        </form>
      )}
    </div>
  );
};

export default AddUserAccounts;
