import React, { useEffect, useState } from 'react'; // Import React
import '../components/publicpage/styles.css'; // Import CSS file
// @ts-ignore
import logo from '../components/publicpage/images/biashara-logo.png'; // Import the bio video
import { useAuth } from '../AuthContext';
import axios from 'axios';
import { API_BASE_URL, API_ENDPOINTS } from '../my-api/apiConfig';
import { Link, useNavigate } from 'react-router-dom';
import BannerFile from '../components/BannerFile';
import FooterFile from '../components/FooterFile';
import { FaSignOutAlt } from 'react-icons/fa';
const SpaBarberPage = () => {
  const [isNavModalOpen, setIsNavModalOpen] = useState(false);
  const accessToken = localStorage.getItem('accessToken');
  const { user, logout } = useAuth();
  const [userDetails, setUserDetails] = useState<any | null>(null);
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState<number | null>(0); // Initially set to the first link

  useEffect(() => {
    // Set the first link as active when the component mounts
    setActiveLink(0);
  }, []);

  
 

  useEffect(() => {
    const handleMenuToggle = () => {
      const navModal = document.getElementById('navModal');
      if (navModal) {
        if (navModal.style.display === "none" || navModal.style.display === "") {
          navModal.style.display = "flex";
        } else {
          navModal.style.display = "none";
        }
      }
    };

    const handleCloseModal = () => {
      const navModal = document.getElementById('navModal');
      if (navModal) {
        navModal.style.display = "none";
      }
    };

    const handleActivateLinks = () => {
      const navLinks = document.querySelectorAll('.nav-links a');
      navLinks.forEach(link => {
        link.addEventListener('click', () => {
          navLinks.forEach(innerLink => {
            innerLink.classList.remove('active');
          });
          link.classList.add('active');
        });
      });
    };

    // Add event listeners when component mounts
    const menuToggle = document.getElementById('menuToggle');
    const closeModal = document.getElementById('closeModal');

    if (menuToggle) {
      menuToggle.addEventListener('click', handleMenuToggle);
    }

    if (closeModal) {
      closeModal.addEventListener('click', handleCloseModal);
    }

    handleActivateLinks();

    // Cleanup
    return () => {
      if (menuToggle) {
        menuToggle.removeEventListener('click', handleMenuToggle);
      }
      if (closeModal) {
        closeModal.removeEventListener('click', handleCloseModal);
      }
      const navLinks = document.querySelectorAll('.nav-links a');
      navLinks.forEach(link => {
        link.removeEventListener('click', () => {});
      });
    };
  }, []); // Empty dependency array means this effect runs only once after initial render

  // Function to open the modal
  const openNavModal = () => {
    setIsNavModalOpen(true);
  };

  // Function to close the modal
  const closeNavModal = () => {
    setIsNavModalOpen(false);
  };

useEffect(() => {
  const fetchUserDetails = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}${API_ENDPOINTS.SINGLE_USER}/${user?.id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.data && response.data.user) {
        setUserDetails(response.data.user);
      } else {
        console.error('Invalid user data in the server response');
      }
    } catch (error) {
      console.error('Failed to fetch user details:', error);
    }
  };

  if (user) {
    fetchUserDetails();
  }
}, [user, accessToken]);

const handleLogout = async () => {
  try {
    if (accessToken) {
      // Send a logout request to the server with the access token
      await axios.post(
        `${API_BASE_URL}${API_ENDPOINTS.LOGOUT}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Clear the user and access token from local storage
      logout();

      // Navigate to the login page
      navigate('/welcomepage', { replace: true });
    } else {
      console.error('Access token not found.');
    }
  } catch (error: any) {
    console.error('Logout failed:', error.message);
  }
};



    return (
    <>
      <div className="navbar">


        <div className="top-row">
          {/* Logo */}
          <div className="top-nav-pub-logo">
          <img src={logo} alt="Necla Logo" />
          {/* <h3>BIASHARA-Web</h3> */}
          </div>
          
          {/* Group of icons and toggle button */}
          <div className="right-group">
            {/* Bar icon button for smaller devices */}
            <button id="menuToggle" className="menu-toggle" onClick={openNavModal}>
            <i className="fas fa-bars"></i>
            </button>

            
            {/* Icons */}
            <div className="icons">
              <Link to="https://www.google.com/maps/dir//Necla+Spa+%26+Barbershop/@-1.1692746,36.8998078,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x182f419f275016ed:0xb467c20a7ae51900!2m2!1d36.9698484!2d-1.1692755"><span className="icon"><i className="fas fa-map-marker-alt"></i></span></Link>
 
              {/* <span className="icon"><i className="fas fa-user"></i></span> */}
              {/* <span className="icon"><i className="fas fa-shopping-cart"></i><span id="cart-count">0</span></span> */}
         
              {/* <span className="icon"><i className="fas fa-user"></i></span> */}
              < Link  to="/preview-cart-page"><span className="icon"><i className="fas fa-shopping-cart"></i><span id="cart-count">0</span></span></Link>
            <Link to="tel:+254 721 313219"><span className="icon"><i className="fas fa-mobile-alt"></i></span></Link>
     
            </div>
       

              
          </div>

          
          
         {/* Modal View for Nav Links on smaller devices */}
  
      {/* Modal View for Nav Links on smaller devices */}
      <div className="modal" id="navModal" style={{ display: isNavModalOpen ? "flex" : "none" }}>
        <div className="modal-content">
          {/* Button to close the modal */}
          <button id="closeModal" className="close-modal" onClick={closeNavModal}>
            <i className="fas fa-times"></i>
          </button>
          <div className="nav-links">
          {user && userDetails && (
            <>

          <Link to="/auth-adverts" className="know-more-btn">Adverts</Link>
          <Link to="/auth-services" className="know-more-btn">Services</Link>
          {/* <Link to="/maintenance" className="know-more-btn">Products</Link> */}
          <Link to="/about" className="know-more-btn">About</Link>
          <Link to="/contact" className="know-more-btn">Contact</Link>
          <Link to="/appointments" className="know-more-btn">Appointments</Link>
          <Link to="/cart" className="know-more-btn">Cart</Link> 
          <div className='icons'><FaSignOutAlt  onClick={handleLogout} /></div>


           
            
            </>

        )}
        {!user && (
          <>
         <Link to="/adverts" className="know-more-btn">Adverts</Link>
           <Link to="/services" className="know-more-btn">Services</Link>
           <Link to="/products" className="know-more-btn">Products</Link>
           <Link to="/about" className="know-more-btn">About</Link>
           <Link to="/contact" className="know-more-btn">Contact</Link>
           <Link to="/login" className="know-more-btn">Login</Link>
           <Link to="/register" className="know-more-btn">Register</Link>          
          </>


        )}
          </div>
        </div>
      </div>


          {/* Nav Links for larger screens */}
          <div className="nav-links">
          {user && userDetails && (
            <>

          <Link to="/auth-adverts" className="know-more-btn">Adverts</Link>
          <Link to="/auth-services" className="know-more-btn">Services</Link>
          <Link to="/products" className="know-more-btn">Products</Link>
          <Link to="/about" className="know-more-btn">About</Link>
          <Link to="/contact" className="know-more-btn">Contact</Link>
          <Link to="/appointments" className="know-more-btn">Appointments</Link>
          <Link to="/cart" className="know-more-btn">Cart</Link> 
          <div className='icons'><FaSignOutAlt  onClick={handleLogout} /></div>

           
            
            </>

        )}
        {!user && (
          <>
         <Link to="/adverts" className="know-more-btn">Adverts</Link>
           <Link to="/services" className="know-more-btn">Services</Link>
           <Link to="/products" className="know-more-btn">Products</Link>
           <Link to="/about" className="know-more-btn">About</Link>
           <Link to="/contact" className="know-more-btn">Contact</Link>
           <Link to="/login" className="know-more-btn">Login</Link>
           <Link to="/register" className="know-more-btn">Register</Link>             
          </>


        )}
          </div>
          
        </div>
      </div>
      {/* home banner container */}
      <BannerFile />
      <FooterFile />
    </>
  );
};

export default SpaBarberPage;

