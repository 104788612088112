// apiConfig.ts
// export const API_BASE_URL = 'http://127.0.0.1:8000/api';
export const API_BASE_URL = 'https://biasharaweb.co.ke/biasharaweb_api/api';
export const API_ENDPOINTS = {
  LOGIN: '/login',
  ADD_ROLE: '/roles', // Endpoint for adding roles
  ADD_USER: '/register', // Endpoint for adding users
  REGISTER: '/register', // Endpoint for adding users
  LOGOUT: '/logout',
  ROLES: '/roles', // Endpoint for fetching roles
  VIEW_USERS: '/viewusers', // Endpoint for fetching users
  SINGLE_USER: '/singleuser', // Updated endpoint for fetching a single user
  DELETE_USERS: '/deleteusers', // Endpoint for deleting users
  CATEGORIES: '/categories', // Updated endpoint for fetching categories
  SUBCATEGORIES: '/subcategories', // Updated endpoint for fetching categories
  ADD_SUBCATEGORY: '/subcategories', // Updated endpoint for fetching categories
  ADD_CATEGORY: '/categories', // Updated endpoint for fetching categories
  ADS: '/ads', // Updated endpoint for fetching categories
  ONEAD: '/onead', // Updated endpoint for fetching categories
  UPDATEADS: '/updateads', // Updated endpoint for fetching categories
  PUBLICADS: '/freeads', // Updated endpoint for fetching categories
  USER_ADS: '/user-ads', // Endpoint for fetching user ads
  // Add other endpoints as needed
  COLLECTIONS: '/collections', // Add the collections endpoint here
  GUEST_COLLECTIONS: '/guestcollections', // Add the collections endpoint here
  GUEST_CATEGORY_COLLECTIONS: 'guestcategorycollections',
  CATEGORY_COLLECTIONS: '/categorycollections', // Add the category endpoint here
  VIEW_ROOMS: '/rooms',
  SERVICES: '/spaservices',
  CART: '/cart',
  APPOINTMENTS: '/appointments',
  BOOKINGS: '/bookings',
  ROOMS: '/rooms',
  // public Routings
  PRODUCT_CATEGORIES: '/public/productCategory',
  PRODUCT_SUB_CATEGORIES: '/public/productSubCategory',
  PRODUCT_CATEGORY_SUB_CATEGORY: '/public/product-subcategories',
  PRODUCT_LIST_ITEMS: '/public/productItem',
  PRODUCT_TYPES: '/public/productType',
  PRODUCT_BRAND: '/public/productBrand',
  AGE_SET: '/public/ageGroup',
  SINGLE_PRODUCT: '/public/product-items',

  // Protected Routings
  AUTH_PRODUCT_CATEGORIES: '/productCategory',
  AUTH_PRODUCT_SUB_CATEGORIES: '/productSubCategory',
  AUTH_PRODUCT_BRANDS: '/productBrand',
  AUTH_PRODUCT_TYPES: '/productType',
  AUTH_AGE_SET: '/ageGroup',
  AUTH_PRODUCT_LIST_ITEMS: '/productItem',

  

  
  
  
};
