import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_BASE_URL, API_ENDPOINTS } from '../../my-api/apiConfig';
import { useAuth } from '../../AuthContext';

interface ProductCategory {
  id: number;
  product_category_name: string;
  product_category_description: string;
}

const ProductCategories: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const accessToken = localStorage.getItem('accessToken');

  const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchProductCategories = async () => {
      try {
        console.log("API_BASE_URL:", API_BASE_URL);
        console.log("API_ENDPOINT:", API_ENDPOINTS.AUTH_PRODUCT_CATEGORIES);
        console.log("Access Token:", accessToken);
  
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.AUTH_PRODUCT_CATEGORIES}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          console.log("Fetched Data:", data);
          // Accessing the nested `product_categories` field
          if (data.product_categories && Array.isArray(data.product_categories)) {
            setProductCategories(data.product_categories);
          } else {
            console.error('Unexpected data structure:', data);
          }
        } else {
          console.error('Error fetching product categories:', response.statusText);
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };
  
    fetchProductCategories();
  }, [accessToken]);
  

  const handleEdit = (categoryId: number) => {
    navigate(`/edit-product-category/${categoryId}`);
  };

  const handleDelete = async (categoryId: number) => {
    try {
      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.AUTH_PRODUCT_CATEGORIES}/${categoryId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        setSuccessMessage(`Product category with ID ${categoryId} deleted successfully`);
        setProductCategories(productCategories.filter(category => category.id !== categoryId));
        window.location.reload(); // Refresh the page after a successful delete
      } else {
        console.error(`Error deleting product category with ID ${categoryId}`);
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  const filteredProductCategories = productCategories.filter((category) =>
    category.product_category_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentProductCategories = filteredProductCategories.slice(indexOfFirstRecord, indexOfLastRecord);

  const handleRecordsPerPageChange = (value: number) => {
    setRecordsPerPage(value);
    setCurrentPage(1);
  };

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div className="table-container">
      <h2>Product Category Management</h2>
      <div className="role-buttons">
        <Link to="/dashboard">
          <button className="back-button">
            <i className="fas fa-arrow-left"></i>Back
          </button>
        </Link>
        <Link to="/product-category/create-new">
          <button className="add-button">
            <i className="fas fa-plus"></i> Add
          </button>
        </Link>
      </div>
      {successMessage && <div className="success-message">{successMessage}</div>}
      <div className="role-search-form">
        <input
          type="text"
          placeholder="Search product categories..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button className="search-button" onClick={() => console.log('Search')}>
          <i className="fas fa-search"></i>
        </button>
      </div>
      <div className="records-per-page">
        <label>filter:</label>
        <select
          value={recordsPerPage}
          onChange={(e) => handleRecordsPerPageChange(Number(e.target.value))}
        >
          {[5, 10, 15].map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <table className="custom-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentProductCategories.map((category) => (
            <tr key={category.id}>
              <td>{category.product_category_name}</td>
              <td>{category.product_category_description}</td>
              <td className="action-btn-wrapper">
                <button className="action-button" onClick={() => handleEdit(category.id)}>
                  <i className="fas fa-edit"></i>
                </button>
                <button className="action-button" onClick={() => handleDelete(category.id)}>
                  <i className="fas fa-trash-alt"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        {Array.from({ length: Math.ceil(filteredProductCategories.length / recordsPerPage) }).map(
          (_, index) => (
            <button key={index + 1} onClick={() => paginate(index + 1)}>
              {index + 1}
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default ProductCategories;
