import React, { useEffect, useState } from 'react';
import '../components/publicpage/styles.css';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL, API_ENDPOINTS } from '../my-api/apiConfig';
import { Button } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { useAuth } from '../AuthContext';

interface ProductItem {
  id: number;
  product_name: string;
  product_description: string;
  product_price: number;
  images: {
    id: number;
    path: string;
    filename: string;
  }[];
}

const SpaBarberPage = () => {
  const [productItem, setProductItem] = useState<ProductItem | null>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const { productId } = useParams();
  const [isCartOpen, setIsCartOpen] = useState(true);
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('accessToken');
  const { user, logout } = useAuth();
  const [productCartItems, setProductCartItems] = useState([]);

  const handleCloseCart = () => {
    setIsCartOpen(false);
    navigate('/');
  };

  const formatPrice = (price: number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'KES' }).format(price);
  };

  useEffect(() => {
    const fetchSingleProductItem = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}${API_ENDPOINTS.SINGLE_PRODUCT}/${productId}`);
        if (response.data && response.data.product_item) {
          setProductItem(response.data.product_item);
        } else {
          console.error('Invalid product item data in the server response');
        }
      } catch (error) {
        console.error('Failed to fetch product item:', error);
      }
    };

    if (productId) {
      fetchSingleProductItem();
    }
  }, [productId]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isHovering) {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % (productItem?.images.length ?? 0));
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [isHovering, productItem]);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % (productItem?.images.length ?? 0));
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? (productItem?.images.length ?? 0) - 1 : prevSlide - 1));
  };

  const goToSlide = (slideIndex: number) => {
    setCurrentSlide(slideIndex);
  };

  const addToCart = async (productId: number) => {
    if (!productItem) {
      console.error('Product item is null');
      return;
    }

    if (isAuthenticated()) {
      try {
        // User is authenticated, check if product is already in the cart
        const headers = {
          'Authorization': `Bearer ${accessToken}`,
        };

        const response = await axios.get(`${API_BASE_URL}/product_carts`, { headers });
        const existingProduct = Array.isArray(response.data) ? response.data.find((item: any) => item.product_item_id === productId) : null;
        
        if (existingProduct) {
          alert('Product already exists in the cart');
        } else {
          // Add product to cart
          const requestData = {
            product_item_id: productId,
            customer_id: getUserId(),
          };
        
          await axios.post(`${API_BASE_URL}/product_carts`, requestData, { headers });
          navigate('/preview-cart-page');
        }
        
      } catch (error) {
        console.error('Failed to add to cart:', error);
      }
    } else {
      // User is not authenticated, check if product is already in the local storage
      const cartItems = localStorage.getItem('cartItems') || '[]';
      const parsedCartItems = JSON.parse(cartItems);

      const existingProduct = parsedCartItems.find((item: any) => item.product_id === productId);
      if (existingProduct) {
        alert('Product already exists in the cart');
      } else {
        const newCartItem = {
          product_id: productItem.id,
          product_name: productItem.product_name,
          product_quantity: 1, // Default quantity to 1
          product_price: productItem.product_price,
          image: productItem.images[0]?.path // Assuming you want to use the first image
        };
        parsedCartItems.push(newCartItem);
        localStorage.setItem('cartItems', JSON.stringify(parsedCartItems));
        navigate('/preview-cart-page');
      }
    }
  };

  const isAuthenticated = () => {
    const isLoggedIn = !!accessToken;
    console.log('Is user logged in:', isLoggedIn);
    return isLoggedIn;
  };

  const getUserId = () => {
    // Implement logic to get user ID
    // For example, retrieve it from localStorage or sessionStorage
    return user?.id;
  };

  if (!productItem) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="preview-page-navigation">
        {isCartOpen && (
          <Button className="close-cart-button" onClick={handleCloseCart}>
            <FaTimes />
          </Button>
        )}
      </div>
      <hr />
      <section className="product-preview-container">
        <div
          className="product-image-slider"
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <div className="product-slide-window">
            {productItem.images.map((image, index) => (
              <div
                key={image.id}
                className="product-slide"
                style={{ display: index === currentSlide ? 'block' : 'none' }}
              >
                <img src={API_BASE_URL + image.path} alt={image.filename} />
              </div>
            ))}
          </div>
          <div className="indicators">
            {productItem.images.map((_, index) => (
              <button
                key={index}
                className={`indicators-dot ${index === currentSlide ? 'active' : ''}`}
                onClick={() => goToSlide(index)}
              />
            ))}
          </div>
          <button className="prev" onClick={prevSlide}>
            <i className="fas fa-chevron-left"></i>
          </button>
          <button className="next" onClick={nextSlide}>
            <i className="fas fa-chevron-right"></i>
          </button>
        </div>
        <div className="product-info-description">
          <div className="product-content-section">
            <h2>{productItem.product_name}</h2>
            <p dangerouslySetInnerHTML={{ __html: productItem.product_description }}></p>
            <h2>PRICE: {formatPrice(productItem.product_price)}</h2>
          </div>
          <div className="product-action-btns">
            <button onClick={() => addToCart(productItem.id)}>Add to cart</button>
          </div>
        </div>
      </section>
    </>
  );
};

export default SpaBarberPage;
